@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CatalogWidget {
    &-Item {
        padding: 0 25px 0 0;
    }

    &-NoProducts {
        font-size: 12px;
        background: $color-neutral-20;
        height: 190px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 44px;
    }

    &-Loader {
        height: 190px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.RecommendedCatalogWidget {
    padding: 20px 0;
    z-index: 1;

    .TypographyHeader {
        text-align: center;
        margin-top: 0;
        margin-bottom: 48px;
    }
}

@include mobile {
    .CatalogWidget {
        &_secondary {
            .CatalogWidget {
                &-Item {
                    padding: 0;
                }
            }
        }
    }
}

@include mobileAndTablet {
    .CatalogWidget {
        margin-bottom: 45px;

        &_secondary {
            padding: 0 50px;

            .SliderNextArrow {
                bottom: 50%;
                right: -50px;
            }

            .SliderPrevArrow {
                bottom: 50%;
                left: -50px;
            }
        }
    }

    .RecommendedCatalogWidget {
        .TypographyHeader {
            margin-bottom: 24px;
        }
    }
}

@include tablet {
    .CatalogWidget {
        &-NoProducts, &-Loader {
            height: 442px;
        }

        &_secondary {
            padding: 0 73px;

            .SliderNextArrow {
                right: -73px;
            }

            .SliderPrevArrow {
                left: -73px;
            }
        }
    }
}

@include mobileAndTablet {
    .RecommendedCatalogWidget {
        margin-right: -16px;
    }

    .RecommendedCatalogWidget,
    .CatalogWidget:not(.CatalogWidget_secondary) {
        .slick-slider .slick-list {
            overflow-y: hidden;
            overflow-x: scroll;
            scroll-behavior: auto;

            &::-webkit-scrollbar {
                display: none;
                height: 0;
            }
        }
    }
}

@include tabletAndAbove {
    .CatalogWidget {
        margin-bottom: 30px;
    }
}

@include desktopAndAbove {
    .CatalogWidget {
        &-Item {
            padding: 0 10px 0;
        }

        &-NoProducts, &-Loader {
            height: 445px;
        }

        .slick-slider {
            margin-left: -10px;
            margin-right: -10px;
        }

        .slick-list {
            overflow: visible;
            z-index: 5;
        }

        .SliderPrevArrow, .SliderNextArrow {
            height: 100%;
            bottom: 0;
            transform: none;
            display: flex;
            align-items: center;
            width: 35px;
            background: $color-neutral-5;
            z-index: 11;
        }

        .SliderPrevArrow {
            left: -34px;
        }

        .SliderNextArrow {
            justify-content: flex-end;
            right: -34px;
            z-index: 10;
        }

        &_secondary {
            padding-left: 75px;
            padding-right: 75px;

            &::before {
                position: absolute;
                content: '';
                height: 100%;
                background: $color-neutral-5;
                width: calc((100vw - 100%) / 2);
                left: calc(((100vw - 100%) / 2) * -1);
                top: 0;
                z-index: 6;
            }

            &::after {
                position: absolute;
                content: '';
                height: 100%;
                background: $color-neutral-5;
                width: calc((100vw - 100%) / 2);
                right: calc(((100vw - 100%) / 2) * -1);
                top: 0;
                z-index: 6;
            }

            .CatalogWidget {
                &-Item {
                    padding: 0 30px 0;
                }
            }

            .SliderPrevArrow, .SliderNextArrow {
                width: 73px;
            }

            .SliderPrevArrow {
                left: -73px;
            }

            .SliderNextArrow {
                right: -73px;
            }
        }
    }
}

